<script>
// Extensions
import View from "@/views/View";

// Mixins
import LoadSections from "@/mixins/load-sections";

export default {
  name: "MyAccount",

  metaInfo: { title: "My Parameters Resource" },

  extends: View,

  mixins: [
    LoadSections([
      // 'sections/hero-alt',
      "resource/sections/resource-parameters",
    ]),
  ],

  props: {
    id: {
      type: String,
      default: "resourceparameters",
    },
  },
};
</script>
